<template>
  <div>
    <el-tabs v-model="activeName">
      <el-button type="warning" @click="back()" size="small">《 返回</el-button>
      <el-button
        type="danger"
        @click="rejectRouteFn()"
        size="small"
        style="margin-left: 200px;"
        v-if="route.updated&&route.state==2"
      >退回为草稿</el-button>
      <el-button
        type="success"
        @click="authRouteFn()"
        size="small"
        style="margin-left: 40px;"
        v-if="route.updated&&route.state==2"
      >检查完成</el-button>
      <el-tab-pane label="线路" name="1" v-if="route">
        <el-row :gutter="100" style="margin:50px 0">
          <el-col :span="12">
            <table>
              <thead>
                <td width="120px">名称</td>
                <td>内容</td>
              </thead>
              <tr>
                <td>名称</td>
                <td>{{route.name}}</td>
              </tr>
              <tr>
                <td>ID</td>
                <td>{{route.id}}</td>
              </tr>
              <tr>
                <td>状态</td>
                <td>{{route.state}}</td>
              </tr>
              <tr>
                <td>作者</td>
                <td>{{route.idUser}} . {{ route.user.nickName }}</td>
              </tr>
              <tr>
                <td>时间</td>
                <td>{{route.duration}}天</td>
              </tr>
              <tr>
                <td>点赞</td>
                <td>{{route.thumbs}}</td>
              </tr>
              <tr>
                <td>行程</td>
                <td>{{countTotal.distanceTotal}}.{{ countTotal.timeTotal }}</td>
              </tr>
              <tr>
                <td>起点</td>
                <td>{{routeStart.name}}</td>
              </tr>
            </table>

            <div style="font-size: 12px; margin-top:40px" v-if="routePositions.length>0">
              <table
                v-for="(item,index) in routePositions"
                :key="index"
                style="padding: 10px 0; border-top:1px dashed #555"
              >
                <tr>
                  <td width="30%">第{{ index+1 }}天</td>
                  <td>{{countSub[index].distanceSub}}.{{countSub[index].timeSub }}</td>
                </tr>
                <tr v-for="(sight,i) in item" :key="i">
                  <td>
                    <i :class="sight.type==1?'el-icon-picture-outline':'el-icon-s-home'"></i>
                    {{ sight.name }}
                  </td>
                  <td>{{sight.drivingDistanceName}}.{{sight.drivingTimeName }}</td>
                </tr>
                <tr>
                  <td>日攻略</td>
                  <td>
                    <div v-html="route.introDay[index].text" style="padding: 10px 0;color:#777"></div>
                  </td>
                </tr>
              </table>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="coverimg">
              <img :src="'https://server.solar960.com'+route.img" width="50%" alt />
            </div>
            <div v-html="route.intro" style="padding: 0 30px"></div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <!-- <el-tab-pane label="地图" name="2">
        <mapview :positions="routePositionsRaw" v-if="activeName=='2'" />
      </el-tab-pane> -->
      <el-tab-pane :label="commentCount==0?'评论':'评论('+commentCount+')'" name="3">
        <commentpage :custId="'route'" @count="setCommentCount" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import commentpage from "@/components/CommentPage";
// import mapview from "../components/MapView";
import { adminGetRoute, authRouteAndGuide } from "@/api/api";

export default {
  components: {
    commentpage,
    // mapview
  },
  data() {
    return {
      activeName: "1",
      id: JSON.parse(this.$route.query.id),
      route: {},
      mapShow: false,
      comments: [],
      countSub: [],
      routePositions: [],
      countTotal: [],
      routeEnd: {},
      routeStart: {},
      routePositionsRaw: [],
      commentCount: 0
    };
  },
  created() {
    this.adminGetRoute();
  },

  methods: {
    // 获取景区详情
    adminGetRoute() {
      const that = this;
      adminGetRoute({
        id: that.id
      }).then(res => {
        if (res.result) {
          that.route = res.data.route;
          that.routePositions = res.data.routePositions;
          that.countSub = res.data.countSub;
          that.countTotal = res.data.countTotal;
          that.routeStart = res.data.routeStart;
          this.routePositionsRaw = res.data.routePositionsRaw;
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    rejectRouteFn() {
      const that = this;
      this.$prompt("请输入原因", "撤回为草稿", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^\S{1,}$/,
        inputErrorMessage: "请输入原因",
        closeOnClickModal: false
      })
        .then(({ value }) => {
          authRouteAndGuide({
            id: that.route.id,
            type: "reject",
            value: value,
            model: "route"
          }).then(res => {
            if (res.result) {
              that.$message({
                type: "warning",
                message: "已退回"
              });
              let getFirstAuth = that.$store.getters.getFirstAuth;
              getFirstAuth.route = getFirstAuth.route - 1;
              that.$store.dispatch("setFirstAuth", getFirstAuth);
              that.back();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消"
          });
        });
    },
    authRouteFn() {
      const that = this;
      this.$confirm("检查通过？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          authRouteAndGuide({
            id: that.route.id,
            type: "auth",
            model: "route"
          }).then(res => {
            if (res.result) {
              that.$message({
                type: "success",
                message: "已通过"
              });
              let getFirstAuth = that.$store.getters.getFirstAuth;
              getFirstAuth.route = getFirstAuth.route - 1;
              that.$store.dispatch("setFirstAuth", getFirstAuth);
              that.back();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消更改"
          });
        });
    },
    setCommentCount(e) {
      this.commentCount = e;
    }
  }
};
</script>

<style scoped>
.coverimg {
  text-align: center;
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px dashed #aaa;
}
</style>